import { cva } from "class-variance-authority";
import classNames from "classnames";

export const clearVariants = cva("h-4 w-4 mr-2", {
  variants: {
    color: {
      lightGrey: "",
      grey: "",
    },
    mode: {
      dark: "",
      light: "",
    },
    disabled: {
      true: "cursor-not-allowed",
      false: "",
    },
    loading: {
      true: "",
      false: "",
    },
  },

  compoundVariants: [
    {
      color: "lightGrey",
      mode: "light",
      className: "text-frGrey-400 group-hover/cop-select:text-frGrey-350",
    },
    {
      color: "lightGrey",
      mode: "dark",
      className: "text-frGrey-300 group-hover/cop-select:text-frGrey-400",
    },
    {
      disabled: true,
      mode: "dark",
      color: "lightGrey",
      className: "text-frGrey-500",
    },
    {
      disabled: true,
      mode: "light",
      color: "lightGrey",
      className: "opacity-70",
    },
    {
      color: "grey",
      mode: "light",
      className: "text-frGrey-400 group-hover/cop-select:text-frGrey-350",
    },
    {
      color: "grey",
      mode: "dark",
      className: "text-neutral-200 group-hover/cop-select:text-neutral-50",
    },
    {
      disabled: true,
      loading: false,
      mode: "dark",
      color: "grey",
      className: "text-neutral-600",
    },
    {
      disabled: true,
      mode: "light",
      color: "grey",
      className: "opacity-70",
    },
  ],

  defaultVariants: {
    color: "lightGrey",
    mode: "light",
    disabled: false,
    loading: false,
  },
});

export const caretVariants = cva(
  "transition-transform duration-300 ease-in-out w-4 h-4",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
      error: {
        true: "",
        false: "",
      },
      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },
      isOpen: {
        true: "transform rotate-180",
        false: "",
      },
      loading: {
        true: "",
        false: "",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "light",
        error: false,
        className: "text-frGrey-400",
      },
      {
        color: "lightGrey",
        mode: "dark",
        error: false,
        className: "text-frGrey-300",
      },
      {
        color: "grey",
        mode: "light",
        error: false,
        className: "text-frGrey-400",
      },
      {
        color: "grey",
        mode: "dark",
        error: false,
        className: "text-neutral-200 group-hover/cop-select:text-neutral-50",
      },
      {
        error: true,
        className: "text-error",
      },
      {
        disabled: true,
        loading: false,
        error: false,
        mode: "dark",
        color: "lightGrey",
        className: "text-neutral-500",
      },
      {
        disabled: true,
        error: false,
        mode: "dark",
        color: "grey",
        className: "text-neutral-500 group-hover/cop-select:text-neutral-500",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      error: false,
      isOpen: false,
      loading: false,
    },
  }
);

export const inputWrapperVariants = cva(
  "relative cursor-default overflow-hidden text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 " +
    "flex border text-base text-dark-grey shadow-sm focus:outline-none focus:ring-2 focus:ring-accent-light leading-none " +
    "group/cop-select",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },

      error: {
        true: "",
        false: "",
      },

      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },

      rounded: {
        true: "rounded-t-2xl rounded-b-2xl",
        false: "rounded-lg",
      },

      multiple: {
        true: "",
        false: "h-8 w-full",
      },

      openAndFocused: {
        true: "rounded-b-none",
        false: "",
      },
    },

    compoundVariants: [
      {
        mode: "light",
        color: "lightGrey",
        error: false,
        className: "border-frGrey-300",
      },

      {
        color: "grey",
        mode: "dark",
        openAndFocused: true,
        className: "!border-b-transparent",
      },
      {
        color: "grey",
        mode: "light",
        openAndFocused: true,
        className: "!border-b-transparent",
      },

      {
        color: "lightGrey",
        error: true,

        className: "border-error focus:border-error focus:ring-error",
      },
      {
        mode: "dark",
        color: "lightGrey",
        error: false,
        className: "border-frGrey-640 bg-frGrey-790 hover:bg-frGrey-640",
      },
      {
        mode: "dark",
        color: "lightGrey",
        openAndFocused: true,
        error: false,
        className: "bg-frGrey-640",
      },
      {
        mode: "dark",
        color: "lightGrey",
        error: true,
        className: "bg-frGrey-790 hover:bg-frGrey-640",
      },

      {
        mode: "light",
        color: "grey",
        error: false,
        className: "border-frGrey-300",
      },
      {
        color: "grey",
        error: true,

        className: "border-error focus:border-error focus:ring-error",
      },
      {
        mode: "dark",
        color: "grey",
        error: false,
        className: "border-neutral-600 bg-neutral-900 hover:border-neutral-400",
      },

      {
        mode: "dark",
        color: "grey",
        error: false,
        openAndFocused: true,
        className: "border-neutral-400",
      },
      {
        mode: "dark",
        color: "grey",
        error: false,
        disabled: true,
        className: "border-neutral-600 hover:border-neutral-600",
      },
      {
        mode: "dark",
        color: "grey",
        error: true,
        disabled: false,
        className: "bg-frGrey-985 hover:bg-frGrey-790", // TODO: update when error state is designed
      },
      {
        mode: "dark",
        color: "grey",
        error: true,
        disabled: true,
        className: "",
      },

      {
        rounded: true,
        multiple: true,
        className: "pl-3",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      error: false,
      disabled: false,
      rounded: false,
      multiple: false,
      openAndFocused: false,
    },
  }
);

export const inputVariants = cva(
  "bg-transparent border-none py-[7px] px-3 text-xs leading-4.5 font-normal font-roboto focus:ring-0 focus:outline-none",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
      error: {
        true: "",
        false: "",
      },
      disabled: {
        true: "cursor-not-allowed",
        false: "",
      },
      multiple: {
        true: "",
        false: "w-full",
      },
      pillsMode: {
        true: "",
        false: "",
      },
      loading: {
        true: "",
        false: "",
      },
    },

    compoundVariants: [
      {
        multiple: true,
        pillsMode: true,
        className: "w-full",
      },

      {
        mode: "light",
        color: "lightGrey",
        error: false,
        className: "text-frGrey-600",
      },
      {
        mode: "dark",
        color: "lightGrey",
        error: false,
        className: "text-frGrey-300",
      },
      {
        mode: "light",
        color: "grey",
        error: false,
        className: "text-frGrey-600",
      },
      {
        mode: "dark",
        color: "grey",
        error: false,
        className:
          "text-neutral-200 group-hover/cop-select:text-neutral-50 group-data-[open]:text-neutral-50",
      },
      {
        error: true,
        disabled: false,
        className: "text-error",
      },
      {
        error: true,
        disabled: true,
        className: "text-error",
      },
      {
        disabled: true,
        loading: false,
        mode: "dark",
        color: "lightGrey",
        className: "text-neutral-600 group-hover/cop-select:text-neutral-600",
      },
      {
        disabled: true,
        loading: false,
        mode: "dark",
        color: "grey",
        className: "text-neutral-600 group-hover/cop-select:text-neutral-600",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      disabled: false,
      multiple: false,
      pillsMode: false,
      loading: false,
    },
  }
);

export const placeholderVariants = cva(
  "px-3 text-xs leading-4.5 font-normal font-roboto",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
      error: {
        true: "",
        false: "",
      },
      disabled: {
        true: "",
        false: "",
      },
      loading: {
        true: "",
        false: "",
      },
    },

    compoundVariants: [
      {
        mode: "light",
        color: "lightGrey",
        error: false,
        className: "text-frGrey-400",
      },
      {
        mode: "dark",
        color: "lightGrey",
        error: false,
        className: "text-frGrey-300",
      },
      {
        mode: "light",
        color: "grey",
        error: false,
        className: "text-frGrey-400",
      },
      {
        mode: "dark",
        color: "grey",
        error: false,
        className:
          "text-neutral-200 " +
          "group-hover/cop-select:text-neutral-50 group-data-[open]:text-neutral-50", // todo: group-data-open may not exist
      },
      {
        error: true,
        disabled: false,
        className: "text-error",
      },
      {
        error: true,
        disabled: true,
        className: "text-error/50 group-hover/cop-select:text-error/50",
      },
      {
        disabled: true,
        loading: false,
        mode: "dark",
        color: "lightGrey",
        className: "text-neutral-600 group-hover/cop-select:text-neutral-600",
      },
      {
        disabled: true,
        mode: "dark",
        color: "grey",
        className: "text-neutral-600 group-hover/cop-select:text-neutral-600",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      disabled: false,
      loading: false,
    },
  }
);

export const optionsWrapperVariants = cva(
  classNames(
    "px-2 pb-1 no-hover:data-[open]:!max-h-screen no-hover:data-[open]:h-full overflow-y-auto border",
    "overflow-x-hidden empty:invisible z-50 text-base shadow-sm ring-0 focus:outline-none sm:text-sm",
    "pr-2 pt-2"
  ),

  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
      rounded: {
        true: "rounded-b-2xl",
        false: "rounded-b-lg",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "light",
        className: "bg-white border-frGrey-300",
      },
      {
        color: "lightGrey",
        mode: "dark",
        className: "bg-frGrey-640 border-frGrey-640",
      },
      {
        color: "grey",
        mode: "light",
        className: "bg-white border-frGrey-300",
      },
      {
        color: "grey",
        mode: "dark",
        className: "bg-neutral-900 border-neutral-400",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      rounded: false,
    },
  }
);

export const optionVariants = cva(
  classNames(
    "group w-full cursor-pointer rounded select-none p-2 pr-4 flex items-center justify-start text-xs leading-4.5 font-roboto",
    "gap-2"
  ),
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "text-frGrey-300",
        light: "",
      },

      disabled: {
        true: "opacity-50",
        false: "",
      },
      focus: {
        true: "",
        false: "",
      },
      selected: {
        true: "",
        false: "",
      },

      lastPinned: {
        true: "border-b",
        false: "",
      },
      isPinned: {
        true: "",
        false: "",
      },
    },

    compoundVariants: [
      {
        focus: true,
        selected: false,
        mode: "dark",
        className: "bg-neutral-800",
      },
      {
        selected: true,
        mode: "dark",
        className: "bg-neutral-700",
      },
      {
        selected: true,
        mode: "light",
        className: "bg-frGrey-300",
      },

      {
        color: "lightGrey",
        mode: "dark",
        lastPinned: true,
        className: "border-frGrey-500",
      },
      {
        color: "grey",
        mode: "dark",
        lastPinned: true,
        className: "border-frGrey-500",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",

      disabled: false,
      focus: false,
      selected: false,

      lastPinned: false,
      isPinned: false,
    },
  }
);

export const noOptionsMessageVariants = cva(
  "px-3 pb-1 text-xs leading-4.5 font-normal font-roboto overflow-hidden",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
    },

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
    },
  }
);

export const pillVariants = cva(
  "ml-2 text-xs leading-4.5 pl-2 pr-1 py-0.5 font-normal font-roboto rounded-lg",
  {
    variants: {
      color: {
        lightGrey: "",
        grey: "",
      },
      mode: {
        dark: "",
        light: "",
      },
      pillsMode: {
        true: "mt-2",
        false: "",
      },
    },

    compoundVariants: [
      {
        color: "lightGrey",
        mode: "light",
        className: "bg-frGrey-300 text-frGrey-550 border-frGrey-350",
      },
      {
        color: "lightGrey",
        mode: "dark",
        className: "bg-frGrey-550 text-frGrey-300 border-frGrey-525",
      },
      {
        color: "grey",
        mode: "light",
        className: "bg-frGrey-300 text-frGrey-550 border-frGrey-350",
      },
      {
        color: "grey",
        mode: "dark",
        className: "bg-frGrey-550 text-frGrey-300 border-frGrey-525",
      },
    ],

    defaultVariants: {
      color: "lightGrey",
      mode: "light",
      pillsMode: false,
    },
  }
);

import { useState, useMemo, useCallback, forwardRef, useEffect } from "react";
import { defaultSearchOptions, fuzzySearch, SearchOptionsT } from "./search";
import {
  SelectProps,
  MultiSelectProps,
  isSelectGroupArray,
  isSelectGroup,
  SelectOption,
  PinMode,
  SelectGroup,
  ResultOrdering,
  isMultiSelectProps,
  ListOption,
  isSelectGroupBase,
} from "./select.types";
import { IconChevronDown, IconX } from "@tabler/icons-react";

import {
  GroupBase,
  DropdownIndicatorProps,
  ClearIndicatorProps,
  StylesConfig,
  ClassNamesConfig,
  OptionsOrGroups,
} from "react-select";
import { WindowedSelect } from "./react-select-extended/windowed";
import { cn } from "@/lib/css-utils";
import {
  caretVariants,
  clearVariants,
  inputVariants,
  inputWrapperVariants,
  placeholderVariants,
  optionsWrapperVariants,
  optionVariants,
  noOptionsMessageVariants,
  pillVariants,
} from "./styles";
import classNames from "classnames";

function parseAsOptions<T extends Record<string, any>>(
  items: Array<
    | SelectOption<T>
    | (GroupBase<SelectOption<T>> & {
        $level: number;
      })
  >,
  options: (SelectOption<T> | SelectGroup<T>)[]
) {
  for (const item of options) {
    if (isSelectGroup(item)) {
      throw new Error("Items are grouped but grouped prop is set to false.");
    }

    items.push({
      ...item,
    });
  }

  return items as SelectOption<T>[];
}

const onlyItems = <T extends Record<string, any>>(
  options: Array<SelectOption<T> | SelectGroup<T>>
): SelectOption<T>[] => {
  const items: SelectOption<T>[] = [];

  for (const item of options) {
    if (isSelectGroup(item)) {
      items.push(...onlyItems(item.options));
    } else {
      items.push(item);
    }
  }

  return items;
};

const flattenGroups = <T extends Record<string, any>>(
  options: Array<SelectOption<T> | SelectGroup<T>>,
  level = 0
): Array<
  SelectGroup<T> & {
    $level: number;
  }
> => {
  // Items are passed in as a tree structure, we need to flatten it since react-select
  //  does not support nested groups. After we flatten the tree, we use a special
  //  $level property to adjust item padding based on the group level.
  //
  // Additionally when mixing options and groups as children of a group, we move all options
  //  to the top of the group as otherwise it would be weird to have a single non-grouped
  //  option between groups.

  const result: Array<
    SelectGroup<T> & {
      $level: number;
    }
  > = [];

  for (const current of options) {
    if (isSelectGroup(current)) {
      const theGroup: {
        label: string;
        options: SelectOption<T>[];

        $level: number;
      } = {
        label: current.label,
        options: [],
        $level: level,
      };

      const descendants = [];

      for (const child of current.options) {
        if (isSelectGroup(child)) {
          descendants.push(...flattenGroups([child], level + 1));
        } else {
          theGroup.options.push({
            ...child,
            $level: level + 1,
          });
        }
      }

      result.push(theGroup);

      result.push(...descendants);
    } else {
      throw new Error("Items are not grouped but grouped prop is set to true.");
    }
  }

  return result;
};

const parseAsGroups = <T extends Record<string, any>>(
  items: Array<
    | SelectOption<T>
    | (GroupBase<SelectOption<T>> & {
        $level: number;
      })
  >,
  options: Array<SelectOption<T> | SelectGroup<T>>,
  level = 0,
  parentOptions: any[] | undefined = undefined
) => {
  //
  // We currently don't support mixing grouped and non-grouped items in the same select.
  //  If the items are grouped, all items must be grouped.

  for (const group of options) {
    if (isSelectGroup(group)) {
      const theGroup = {
        label: group.label,
        options: [],
        $level: level,
      };

      parseAsGroups(items, group.options, level + 1, theGroup.options);

      items.push(theGroup);
    } else {
      if (level === 0) {
        throw new Error(
          "Items are not grouped but grouped prop is set to true."
        );
      }

      (parentOptions ?? items).push({
        ...group,
        $level: level,
      });
    }
  }
};

const useSelectData = <T extends Record<string, any>>(
  options: SelectOption<T>[] | SelectGroup<T>[],
  multiple: boolean | undefined,
  grouped: boolean | undefined,
  searchOrdering: ResultOrdering,
  searchConfig: SearchOptionsT<SelectOption<T>>,
  notFoundLabel: string,
  emptyLabel: string,

  pinEnabled: boolean | undefined,
  pinnedOptions: string[] | undefined,
  pinMode: PinMode
) => {
  const theOptions = useMemo(() => {
    if (grouped) {
      return flattenGroups(options);
    }

    return parseAsOptions([], options);
  }, [options]);

  // We override the react-select search logic and filter down the options using our own custom search logic. As
  //  react-select does not provide a way to order search results we need to do this manually.
  const [query, setQuery] = useState("");

  const onInputChange = useCallback(
    (value: string) => {
      setQuery(value);
    },
    [setQuery]
  );

  const { finalItems, noOptionsMessage } = useMemo(() => {
    let finalItems = theOptions;

    if (pinnedOptions && pinEnabled && pinnedOptions.length > 0) {
      const pinned = grouped
        ? finalItems.flatMap((group) => {
            if (isSelectGroup(group)) {
              return group.options
                .filter((item) => {
                  if (isSelectGroup(item) || isSelectGroupBase(item)) {
                    return false;
                  }

                  return pinnedOptions.includes(item.value);
                })
                .map((item) => structuredClone(item) as SelectOption<T>);
            }

            return [];
          })
        : finalItems
            .filter((item) => {
              if (isSelectGroup(item) || isSelectGroupBase(item)) {
                return false;
              }

              return pinnedOptions.includes(item.value);
            })
            .map((item) => structuredClone(item) as SelectOption<T>);

      if (pinned.length > 0) {
        pinned[pinned.length - 1].$lastPinned = true;
      }

      for (const item of pinned) {
        item.$isPinRow = true;
      }

      const rest =
        pinMode === PinMode.Duplicate
          ? finalItems
          : finalItems.filter((item) => {
              if (isSelectGroup(item) || isSelectGroupBase(item)) {
                return true;
              }

              return !pinnedOptions.includes(item.value);
            });

      finalItems = (
        grouped
          ? [
              {
                label: "Pinned",
                options: pinned,
              },
              ...rest,
            ]
          : [...pinned, ...rest]
      ) as typeof grouped extends true ? SelectGroup<T>[] : SelectOption<T>[];
    }

    if (!query || finalItems.length === 0) {
      return {
        finalItems: finalItems,
        noOptionsMessage: emptyLabel,
      };
    }

    // When searching and items are grouped, we need to do some magic, essentially we need to flatten the grouped items
    //  and then apply the search logic to the flattened items. After we have the search results, we need to re-group
    //  remove the non-matching items and prune the empty groups.

    const searchItems = (
      grouped ? onlyItems(finalItems) : finalItems
    ) as SelectOption<T>[];

    const results =
      fuzzySearch(
        searchItems,
        searchConfig.keys,
        query,
        searchConfig.threshold,
        searchOrdering
      ) ?? [];

    const noOptionsMessage =
      results.length === 0 && !!query ? notFoundLabel : emptyLabel;

    if (grouped) {
      // Go over finalItems and:
      //
      // - remove non-matching items
      // - remove empty groups
      const resultArr = results.map((item) => item.value);
      const fastResults = new Set(resultArr);

      const cleanedResults = finalItems
        .map((group) => {
          if (!isSelectGroup(group)) {
            return group;
          }

          // Only take the options that are in the fastResults set
          const options = (group.options as SelectOption<T>[]).filter((item) =>
            fastResults.has(item.value)
          );

          // sort the options based on resultArr order (when in SCORE ordering mode)
          if (searchOrdering === ResultOrdering.SCORE) {
            options.sort((a, b) => {
              return resultArr.indexOf(a.value) - resultArr.indexOf(b.value);
            });
          }

          return {
            ...group,
            options,
          };
        })
        .filter((group, idx) => {
          if (isSelectGroup(group)) {
            const options = (group.options as SelectOption<T>[]).filter(
              (item) => fastResults.has(item.value)
            );

            return group.options.length > 0;
          }

          return fastResults.has(group.value);
        });

      if (searchOrdering === ResultOrdering.SCORE) {
        cleanedResults.sort((a, b) => {
          // Sort groups based on the best match in the group
          const aBest = (a.options as SelectOption<T>[])[0];
          const bBest = (b.options as SelectOption<T>[])[0];

          return (
            resultArr.indexOf(aBest.value) - resultArr.indexOf(bBest.value)
          );
        });
      }

      return {
        finalItems: cleanedResults,
        noOptionsMessage,
      };
    }

    return {
      finalItems: results,
      noOptionsMessage,
    };
  }, [
    searchOrdering,
    query,
    theOptions,
    searchConfig.keys,
    searchConfig.threshold,
    emptyLabel,
    notFoundLabel,
    pinEnabled,
    pinnedOptions,
    pinMode,
  ]);

  const resetQuery = useCallback(() => {
    setQuery("");
  }, [setQuery]);

  return {
    theOptions: finalItems as OptionsOrGroups<
      SelectOption<T>,
      GroupBase<SelectOption<T>>
    >,
    multiple: multiple ?? false,
    grouped: isSelectGroupArray(options),

    noOptionsMessage,

    onInputChange: onInputChange,

    resetQuery,
  };
};

const FakeLoader = () => {
  return null;
};

const selectStyles: StylesConfig<any, any, any> = {
  container: (provided) => ({
    ...provided,
    pointerEvents: undefined,
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "32px",
    cursor: undefined,
  }),
  group: (provided, state) => ({
    ...provided,
    paddingLeft: `${state?.data?.$level * 20}px`,
  }),
  option: (provided, state) => ({
    ...provided,

    cursor: undefined,
    fontSize: undefined,
    display: provided.display === "none" ? "none" : undefined,

    paddingLeft: state?.data?.$level
      ? `${state?.data?.$level * 20}px`
      : undefined,
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    display: undefined, // control via class
  }),
};

const largeSelectStyles: StylesConfig<any, any, any> = {
  ...selectStyles,

  control: (provided) => ({
    ...provided,
    minHeight: "40px",
  }),
};

function isOptionDisabled<T extends Record<string, any>>(
  option: SelectOption<T>
) {
  return !!option.disabled;
}

function Select<T extends Record<string, any>>(
  {
    label,
    options,
    className,
    inputWrapperClassName,
    title,

    placeholder = "Select an option",
    emptyLabel = "No options available",
    notFoundLabel = "Nothing found",

    multiple,

    disabled,
    loading,

    grouped,

    loadingAnimation = "dots",

    searchOrdering = ResultOrdering.SCORE,

    hasError,
    errorText,

    searchable = true,

    noClear,
    rounded,

    color = "grey",
    mode = "light",

    size = "md",

    pinEnabled,
    pinOption,
    pinnedOptions,
    pinMode = PinMode.Duplicate,

    multiMode = "simple",

    searchOptions,

    value: ctrlValue,
    onChange,
  }: SelectProps<T> | MultiSelectProps<T>,
  ref: any
) {
  const searchConfig = {
    ...defaultSearchOptions,
    ...searchOptions,
  };

  const { theOptions, noOptionsMessage, resetQuery, onInputChange } =
    useSelectData(
      options,
      multiple,
      grouped,
      searchOrdering,
      searchConfig,
      notFoundLabel,
      emptyLabel,
      pinEnabled,
      pinnedOptions,
      pinMode
    );

  const isDisabled = typeof disabled !== "undefined" ? disabled : !!loading;

  const selectClasses: ClassNamesConfig<any, boolean, GroupBase<any>> = useMemo(
    () => ({
      container: () =>
        loading && loadingAnimation === "pulse" ? "animate-pulse" : "",

      singleValue: (state) =>
        cn(
          inputVariants({
            color,
            mode,
            error: !!hasError,
            disabled: !!state.isDisabled,
            multiple: !!multiple,
            pillsMode: multiMode === "pill",

            loading: !!loading,
          }),
          className
        ),
      placeholder: ({ isDisabled }) =>
        placeholderVariants({
          color,
          mode,
          error: !!hasError,
          disabled: !!isDisabled,
          loading: !!loading,
        }),
      indicatorsContainer: () => "pr-3",
      control: (state: {
        isFocused: boolean;
        menuIsOpen: boolean;
        isDisabled?: boolean;
      }) =>
        cn(
          inputWrapperVariants({
            color,
            mode,
            error: !!hasError,
            disabled: !!state.isDisabled,
            rounded: !!rounded,
            multiple: !!multiple,
            openAndFocused: state.menuIsOpen && state.isFocused,
          }),
          inputWrapperClassName
        ),
      input: (state: { isHidden: boolean; isDisabled?: boolean }) =>
        cn(
          inputVariants({
            color,
            mode,
            error: !!hasError,
            disabled: !!state.isDisabled,
            multiple: !!multiple,
            pillsMode: multiMode === "pill",
            loading: !!loading,
          }),
          className
        ),

      indicatorSeparator: () => "hidden",

      groupHeading: () => "mt-2 text-xs leading-4.5 font-roboto font-medium",

      menu: () =>
        optionsWrapperVariants({
          color,
          mode,
          rounded: !!rounded,
        }),

      option: (props) =>
        cn(
          optionVariants({
            color,
            mode,

            disabled: !!props.isDisabled,

            focus: !!props.isFocused,
            selected: !!props.isSelected,
          })
        ),

      noOptionsMessage: () => noOptionsMessageVariants({ color, mode }),

      multiValue: () =>
        pillVariants({
          color,
          mode,
          pillsMode: multiMode === "pill",
        }),
      multiValueRemove: () =>
        classNames("ml-1", {
          hidden: multiMode === "simple",
          flex: multiMode === "pill",
        }),
    }),
    [
      loading,
      loadingAnimation,
      color,
      mode,
      hasError,
      rounded,
      multiple,
      className,
      inputWrapperClassName,
      multiMode,
    ]
  );

  const [curValue, setCurValue] = useState<
    (typeof multiple extends true ? ListOption[] : ListOption) | null
  >(null);

  // sync externally passed in value to internal state
  useEffect(() => {
    if (ctrlValue === undefined) {
      return;
    }

    if (ctrlValue === null) {
      const newVal = multiple ? [] : null;

      setCurValue(
        newVal as
          | (typeof multiple extends true ? ListOption[] : ListOption)
          | null
      );
      return;
    }

    const newValue = multiple
      ? options.filter((item) => {
          if (isSelectGroup(item) || isSelectGroupBase(item)) {
            return false;
          }

          return (ctrlValue as string[]).includes(item.value);
        })
      : options.find((item) => {
          if (isSelectGroup(item) || isSelectGroupBase(item)) {
            return false;
          }

          return item.value === ctrlValue;
        });

    setCurValue(
      newValue as
        | (typeof multiple extends true ? ListOption[] : ListOption)
        | null
    );
  }, [ctrlValue, multiple, options]);

  const onChangeHandler = useCallback(
    (value: any) => {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        onChange?.(null);
        setCurValue(null);
        return;
      }

      if (
        isMultiSelectProps({
          value,
          multiple,
        })
      ) {
        const newValue = value.map((x: SelectOption<T>) => x.value);

        setCurValue(value);
        onChange?.(newValue);
      } else {
        setCurValue(value);
        onChange?.(value?.value ?? null);
      }
    },
    [multiple, onChange]
  );

  const filterOption = useMemo(() => () => true, []);

  const extraProps: {
    placeholder?: string;

    pinOption?: (optionKey: string) => void;
    pinEnabled?: boolean;
  } = {
    placeholder: placeholder,
  };

  let controlShouldRenderValue: boolean | undefined = undefined;

  if (multiple && multiMode === "simple") {
    if (curValue && Array.isArray(curValue)) {
      controlShouldRenderValue = curValue.length < 2;

      if (!controlShouldRenderValue) {
        extraProps.placeholder = `${curValue.length} items selected`;
      }
    }
  }

  return (
    <div
      title={title}
      className={classNames({ "cursor-not-allowed": isDisabled })}
    >
      <WindowedSelect
        ref={ref}
        // menuIsOpen // for debugging styles inside the dropdown
        isMulti={multiple}
        value={curValue}
        options={theOptions}
        isSearchable={searchable}
        openMenuOnFocus={true}
        hideSelectedOptions={multiple ? false : undefined}
        isClearable={!noClear}
        aria-label={label}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        isOptionDisabled={isOptionDisabled}
        closeMenuOnSelect={multiple ? false : undefined}
        unstyled
        isDisabled={isDisabled}
        isLoading={loading}
        styles={size === "lg" ? largeSelectStyles : selectStyles}
        classNames={selectClasses}
        onChange={onChangeHandler}
        onInputChange={onInputChange}
        onBlur={resetQuery}
        filterOption={filterOption}
        controlShouldRenderValue={controlShouldRenderValue}
        {...extraProps}
        components={{
          DropdownIndicator: (props: DropdownIndicatorProps<any, true>) => (
            <IconChevronDown
              className={caretVariants({
                mode,
                color,
                error: !!hasError,
                disabled: !!props.isDisabled,
                isOpen: props?.selectProps?.menuIsOpen,

                loading: !!loading,
              })}
            />
          ),
          ClearIndicator: (props: ClearIndicatorProps<any, any>) => (
            <div {...props.innerProps}>
              <span className="sr-only">Clear</span>

              <IconX
                className={clearVariants({
                  mode,
                  color,
                  disabled: !!disabled,
                  loading: !!loading,
                })}
              />
            </div>
          ),
          ...(loadingAnimation === "pulse"
            ? {
                LoadingIndicator: FakeLoader,
              }
            : {}),
        }}
        pinOption={pinOption}
        pinEnabled={pinEnabled}
        pinnedOptions={pinnedOptions}
        menuPortalTarget={
          typeof document !== "undefined"
            ? document.querySelector("body")
            : undefined
        }
      />

      {hasError && errorText ? (
        <div className="mt-1 pl-3 pr-3 text-sm text-error">{errorText}</div>
      ) : null}
    </div>
  );
}

export default forwardRef(Select);

const OptionsWrapper = forwardRef(({ children, ...props }: any, ref) => {
  return (
    <div className="copmer-select">
      <div {...props} ref={ref}>
        {children}
      </div>
    </div>
  );
});

OptionsWrapper.displayName = "OptionsWrapper";

import {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import ReactSelect, { GroupBase, Props as SelectProps } from "react-select";

import { MenuList as WindowedMenuList } from "./WindowedMenuList";
import { SelectOptionBase } from "../select.types";
import { ExtendedOption } from "./option";

function WindowedReactSelect<
  T extends SelectOptionBase,
  isMulti extends boolean
>({ components, ...props }: SelectProps<T, isMulti, GroupBase<T>>, ref: any) {
  const timeSinceLastKeyboardNavigation = useRef<number | null>(null);

  const customRef = useRef<any>(null);

  /**
   * We track menu being open or closed and additionally track if the user selected
   *  an option consciously (e.g. by keyboard navigation or by hovering over an option).
   *
   * In case they didn't select an option consciously, we reset the value to null after
   *  a tab key press.
   *
   * This allows the user to tab through the select without selecting an option.
   */
  const isMenuOpen = useRef(false);
  const wasKeyboardNavigation = useRef(false);
  const wasMouseFocus = useRef(false);

  const origValue = useRef(props.value);

  useEffect(() => {
    if (origValue.current !== props.value) {
      origValue.current = props.value;
    }
  }, [props.value]);

  const isMulti = props.isMulti ?? false;

  /**
   * Prevents unwanted scrolling when the user hovers over items with a mouse.
   * Tracks the last keyboard navigation event to distinguish between mouse and
   * keyboard interactions. Since scrolling in the MenuList component occurs
   * only when the focused item changes, we use the last keyboard event timestamp
   * to decide whether to trigger a scroll.
   */
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
        case "PageUp":
        case "PageDown":
        case "Home":
        case "End":
          timeSinceLastKeyboardNavigation.current = performance.now();
          wasKeyboardNavigation.current = true;
          break;

        case "Enter":
          // User pressed Enter to select an option, consider that a keyboard navigation event
          wasKeyboardNavigation.current = true;
          event.stopPropagation();

          break;

        case "Tab":
          if (isMenuOpen.current) {
            if (!wasKeyboardNavigation.current && !wasMouseFocus.current) {
              // When the user has not done any keyboard navigation or mouse focus, bail out of react-select logic
              //  completely, and just focus the next element on the page.
              //
              // This prevents the select from selecting the first option in the list without user knowingly selecting
              //  it.
              event.preventDefault();

              requestAnimationFrame(() => {
                focusNextElement(event.shiftKey ? true : false);
              });
            }
          }

          break;

        case "Escape":
          break;

        default:
          // When user types a character to search for an option, consider that a keyboard navigation event
          wasKeyboardNavigation.current = true;
          break;
      }
    },
    [isMulti]
  );

  const onMenuOpen = useCallback(() => {
    isMenuOpen.current = true;
    wasKeyboardNavigation.current = false;
    wasMouseFocus.current = false;

    if (props.onMenuOpen) {
      props.onMenuOpen();
    }
  }, [props.onMenuOpen]);

  const onMenuClose = useCallback(() => {
    isMenuOpen.current = false;
    wasKeyboardNavigation.current = false;
    wasMouseFocus.current = false;

    if (props.onMenuClose) {
      props.onMenuClose();
    }
  }, [props.onMenuClose]);

  return (
    <ReactSelect
      {...props}
      ref={ref ?? customRef}
      components={{
        ...components,
        MenuList: components?.MenuList ?? WindowedMenuList,
        Option: components?.Option ?? ExtendedOption,
      }}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onKeyDown={onKeyDown}
      timeSinceLastKeyboardNavigationRef={timeSinceLastKeyboardNavigation}
      wasMouseFocusRef={wasMouseFocus}
    />
  );
}

function focusNextElement(reverse: boolean, activeElem?: HTMLElement) {
  /*check if an element is defined or use activeElement*/
  const theActiveElem =
    activeElem instanceof HTMLElement ? activeElem : document.activeElement;

  const queryString = [
      'a:not([disabled]):not([tabindex="-1"])',
      'button:not([disabled]):not([tabindex="-1"])',
      'input:not([disabled]):not([tabindex="-1"])',
      'select:not([disabled]):not([tabindex="-1"])',
      '[tabindex]:not([disabled]):not([tabindex="-1"])',
      /* add custom queries here */
    ].join(","),
    queryResult = Array.prototype.filter.call(
      document.querySelectorAll(queryString),
      (elem) => {
        /*check for visibility while always include the current activeElement*/
        return (
          elem.offsetWidth > 0 ||
          elem.offsetHeight > 0 ||
          elem === theActiveElem
        );
      }
    );
  const indexedList: HTMLElement[] = queryResult
    .slice()
    .filter((elem) => {
      /* filter out all indexes not greater than 0 */
      return elem.tabIndex == 0 || elem.tabIndex == -1 ? false : true;
    })
    .sort((a, b) => {
      /* sort the array by index from smallest to largest */
      return a.tabIndex != 0 && b.tabIndex != 0
        ? a.tabIndex < b.tabIndex
          ? -1
          : b.tabIndex < a.tabIndex
          ? 1
          : 0
        : a.tabIndex != 0
        ? -1
        : b.tabIndex != 0
        ? 1
        : 0;
    });

  const focusable: HTMLElement[] = ([] as HTMLElement[]).concat(
    indexedList,
    queryResult.filter((elem) => {
      /* filter out all indexes above 0 */
      return elem.tabIndex == 0 || elem.tabIndex == -1 ? true : false;
    })
  );

  /* if reverse is true return the previous focusable element
     if reverse is false return the next focusable element */

  const curIndex = focusable.indexOf(theActiveElem as any);

  const newIndex = reverse
    ? (curIndex - 1) % focusable.length
    : (curIndex + 1) % focusable.length;

  const node = focusable[newIndex] ?? focusable[0];

  node?.focus?.();
}
export const WindowedSelect = forwardRef(WindowedReactSelect);

import { GroupBase } from "react-select";
import { SearchOptionsT } from "./search";

export type SelectColor = "lightGrey" | "grey";
export type SelectColorMode = "light" | "dark";

export type ListOption<T = string> = {
  label: string;
  value: T;
};

export type SelectOptionBase = ListOption & {
  disabled?: boolean; // if true, the option is not selectable

  // internal use only
  $lastPinned?: boolean;
  $isPinRow?: boolean;

  // used for sorting the search results by the original order when using ResultOrdering.DATA
  $index?: number;
  // used for adjusting padding left of option that are in a group
  $level?: number;
};

export type SelectOption<T extends Record<string, any> = any> =
  SelectOptionBase & T;

export interface SelectGroup<T extends Record<string, any> = any> {
  label: string;
  options: (SelectOption<T> | SelectGroup<T>)[]; // options in the group

  // used for adjusting padding left of option that are in a group
  $level?: number;
}

export enum ResultOrdering {
  SCORE = "score", // order by match score
  DATA = "data", // order by the options order
}

export enum PinMode {
  Duplicate = "duplicate",
  Single = "single",
}

export interface BaseSelectProps {
  required?: boolean; // default: false
  loading?: boolean; // default: false
  disabled?: boolean; // default: false

  // Set to False to disable searching
  searchable?: boolean; // default: true

  // Set to True if the options are grouped
  grouped?: boolean; // default: false

  // Set to True to disable clearing button
  noClear?: boolean; // default: false

  // Label/placeholder
  label?: string;
  placeholder?: string;
  title?: string;

  emptyLabel?: string;
  notFoundLabel?: string;

  // styling
  mode?: SelectColorMode;
  color?: SelectColor;
  rounded?: boolean; // default: false
  size?: "md" | "lg"; // default: "md"

  // class names
  className?: string;
  inputWrapperClassName?: string;

  // Animation configuration
  loadingAnimation?: "dots" | "pulse";

  // Error state
  hasError?: boolean;
  errorText?: string;

  // search logic
  searchOrdering?: ResultOrdering;

  // Pinning (favorites) params
  pinEnabled?: boolean; // default: true
  pinnedOptions?: string[];
  pinMode?: PinMode; // default: PinMode.Duplicate
  pinOption?: (optionKey: string) => void;
}

export interface SelectProps<T extends Record<string, any> = any>
  extends BaseSelectProps {
  options: SelectOption<T>[] | SelectGroup<T>[];
  value?: string | null;

  multiple?: false;

  onChange?: (value: string | null) => void;

  multiMode?: never;

  searchOptions?: SearchOptionsT<T>;
}

export interface MultiSelectProps<T extends Record<string, any> = any>
  extends BaseSelectProps {
  options: SelectOption<T>[] | SelectGroup<T>[];

  multiple?: true;

  multiMode?: "simple" | "pill"; // default: "simple"

  value?: string[]; // TODO: if this is undefined the form select is uncontrolled, otherwise its controlled
  onChange?: (value: string[] | null) => void;

  searchOptions?: SearchOptionsT<T>;
}

export const isMultiSelectProps = (
  props: Pick<SelectProps | MultiSelectProps, "multiple" | "value">
): props is MultiSelectProps => (props as MultiSelectProps).multiple === true;

export const isSelectGroupArray = <T extends Record<string, any>>(
  items: SelectOption<T>[] | SelectGroup<T>[]
): items is SelectGroup<T>[] =>
  (items as SelectGroup<T>[])[0]?.options !== undefined;

export const isSelectGroup = <T extends Record<string, any>>(
  item: SelectOption<T> | SelectGroup<T> | GroupBase<SelectOption<T>>
): item is SelectGroup<T> => (item as SelectGroup<T>).options !== undefined;

export const isSelectGroupBase = <T extends Record<string, any>>(
  item: SelectOption<T> | SelectGroup<T> | GroupBase<SelectOption<T>>
): item is GroupBase<SelectOption<T>> =>
  (item as GroupBase<SelectOption<T>>).options !== undefined;

import { PinAdapter } from "./pin.types";

export const LocalStoragePinAdapter: PinAdapter = {
  get: async (context: string) => {
    const savedItems = localStorage.getItem(`cop-sel/${context}`);

    if (!savedItems) {
      return [];
    }

    try {
      return JSON.parse(savedItems);
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  save: async (context: string, options: string[]) => {
    localStorage.setItem(`cop-sel/${context}`, JSON.stringify(options));
  },
};

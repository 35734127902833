/** @jsxImportSource @emotion/react */
import { GroupBase, OptionProps } from "react-select";
import { jsx } from "@emotion/react";
import {
  IconCheck,
  IconCircle,
  IconStar,
  IconStarFilled,
} from "@tabler/icons-react";
import { CircleFilledIcon } from "../icons";
import React, { ReactNode, useCallback } from "react";
import classNames from "classnames";

export const ExtendedOption = <
  Option extends Record<string, any>,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: OptionProps<Option, IsMulti, Group>
) => {
  const { children, isDisabled, isFocused, isSelected, innerRef, innerProps } =
    props;

  const css = props.getStyles("option", props);
  const classes = props.cx(
    {
      option: true,
      "option--is-disabled": isDisabled,
      "option--is-focused": isFocused,
      "option--is-selected": isSelected,
      "mb-2": props.data?.$lastPinned,
    },
    props.getClassNames("option", props),
    props.className
  );

  let checkNode: ReactNode = null;

  if (props.selectProps.isMulti) {
    checkNode = (
      <div className="h-4 w-4 flex items-center justify-center">
        <IconCheck
          className={classNames("selected-icon h-4 w-4", {
            invisible: !isSelected,
            visible: isSelected,
          })}
        />
      </div>
    );
  }

  const pinEnabled = props.selectProps?.pinEnabled;
  const pinOption = props.selectProps?.pinOption;
  const pinnedOptions = props.selectProps?.pinnedOptions;
  const itemValue = props.data.value;

  const noPropagate = useCallback(
    (e: React.MouseEvent<any> | React.TouchEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  const node = (
    <div
      css={css}
      className={classes}
      ref={innerRef}
      aria-disabled={isDisabled}
      {...innerProps}
    >
      {checkNode}

      <div className="leading-none truncate">{children}</div>

      {pinEnabled && pinOption ? (
        <>
          <span className="flex-grow" />

          <button
            type="button"
            className="cursor-pointer group/pin"
            onClick={noPropagate}
            onMouseDown={noPropagate}
            onMouseUp={(e) => {
              e.preventDefault();
              e.stopPropagation();
              pinOption(itemValue);
            }}
            onTouchStart={noPropagate}
            onTouchEnd={(e) => {
              e.preventDefault();
              e.stopPropagation();
              pinOption(itemValue);
            }}
          >
            <IconStarFilled
              className={classNames("ml-1 h-4 w-4", {
                "hidden group-hover/pin:block":
                  !pinnedOptions?.includes(itemValue),
                "block group-hover/pin:hidden":
                  pinnedOptions?.includes(itemValue),
              })}
            />
            <IconStar
              className={classNames("ml-1 h-4 w-4", {
                "hidden group-hover/pin:block":
                  pinnedOptions?.includes(itemValue),
                "block group-hover/pin:hidden":
                  !pinnedOptions?.includes(itemValue),
              })}
            />
          </button>
        </>
      ) : null}
    </div>
  );

  if (props.data?.$lastPinned) {
    return (
      <div className="pb-4 w-full relative">
        {node}
        <div className="h-px bg-frGrey-500 absolute left-0 right-0 bottom-2" />
      </div>
    );
  }

  return node;
};

import * as React from "react";

export function isFocused({ props }: any) {
  return props?.isFocused === true;
}

export function flattenGroupedChildren(children: Array<any>) {
  return children.reduce((result, child) => {
    if (
      child.props.children != null &&
      typeof child.props.children === "string"
    ) {
      return [...result, child];
    } else {
      const {
        props: { children: nestedChildren = [] },
      } = child;

      return [
        ...result,
        React.cloneElement(child, { type: "group" }, []),
        ...nestedChildren,
      ];
    }
  }, []);
}
export function getCurrentIndex(
  children?: ReturnType<typeof React.Children.toArray>
) {
  return Math.max(children?.findIndex?.(isFocused) ?? 0, 0);
}

export function createGetHeight({
  groupHeadingStyles,
  noOptionsMsgStyles,
  optionStyles,
  loadingMsgStyles,
}: {
  groupHeadingStyles: any;
  noOptionsMsgStyles: any;
  optionStyles: any;
  loadingMsgStyles: any;
}) {
  return function getHeight(child: any) {
    const {
      props: {
        type,
        children,
        inputValue,
        selectProps: { noOptionsMessage, loadingMessage },
      },
    } = child;

    if (type === "group") {
      const { height = 25 } = groupHeadingStyles;
      return height;
    } else if (type === "option") {
      const { height = 35 } = optionStyles;
      return height;
    } else if (
      typeof noOptionsMessage === "function" &&
      children === noOptionsMessage({ inputValue })
    ) {
      const { height = 35 } = noOptionsMsgStyles;
      return height;
    } else if (
      typeof loadingMessage === "function" &&
      children === loadingMessage({ inputValue })
    ) {
      const { height = 35 } = loadingMsgStyles;
      return height;
    } else {
      return 35;
    }
  };
}

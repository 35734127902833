import * as React from "react";
import {
  ListChildComponentProps,
  VariableSizeList as List,
} from "react-window";
import { OptionProps, GroupBase, MenuListProps } from "react-select";

import {
  createGetHeight,
  getCurrentIndex,
  flattenGroupedChildren,
} from "./util";
import classNames from "classnames";

interface ListChildProps<T> extends ListChildComponentProps<T> {
  style: React.CSSProperties;
}

interface OptionTypeBase {
  [key: string]: any;
}

interface SelectOptionBase {
  label: string;
  value: string;
}

const ensureNumber = (value: any): number => {
  if (value === undefined) {
    return 0;
  }

  return typeof value === "number" ? value : parseFloat(value);
};

export function MenuList<T extends SelectOptionBase, isMulti extends boolean>(
  props: MenuListProps<T, isMulti, GroupBase<T>>
) {
  const children = React.useMemo(() => {
    const children = React.Children.toArray(props.children);

    const head = children[0] || {};

    if (
      React.isValidElement<
        OptionProps<OptionTypeBase, boolean, GroupBase<OptionTypeBase>>
      >(head)
    ) {
      const { props: { data: { options = [] } = {} } = {} } = head;
      const groupedChildrenLength = options.length;
      const isGrouped = groupedChildrenLength > 0;
      const flattenedChildren = isGrouped && flattenGroupedChildren(children);

      return isGrouped ? flattenedChildren : children;
    } else {
      return [];
    }
  }, [props.children]);

  const timeSinceLastKeyboardNavigationRef =
    props.selectProps?.timeSinceLastKeyboardNavigationRef;
  const wasMouseFocusRef = props.selectProps?.wasMouseFocusRef;

  const { getStyles, getClassNames } = props;

  const classes = getClassNames("menuList", props as any);

  const groupHeadingStyles = getStyles("groupHeading", props as any);
  const loadingMsgStyles = getStyles("loadingMessage", props);
  const noOptionsMsgStyles = getStyles("noOptionsMessage", props);
  const optionStyles = getStyles("option", props as any);
  const getHeight = createGetHeight({
    groupHeadingStyles,
    noOptionsMsgStyles,
    optionStyles,
    loadingMsgStyles,
  });

  const heights = React.useMemo(() => children.map(getHeight), [children]);
  const currentIndex = React.useMemo(() => {
    const idx = getCurrentIndex(children);
    return idx;
  }, [children]);

  const itemCount = children.length;

  const [measuredHeights, setMeasuredHeights] = React.useState<
    Record<number, number>
  >({});

  // calc menu height
  const {
    maxHeight,
    paddingBottom: pB,
    paddingTop: pT,
    ...menuListStyle
  } = getStyles("menuList", props);

  const paddingBottom = ensureNumber(pB);
  const paddingTop = ensureNumber(pT);

  const totalHeight = React.useMemo(() => {
    return heights.reduce((sum: number, height: number, idx: number) => {
      if (measuredHeights[idx]) {
        return sum + measuredHeights[idx];
      } else {
        return sum + height;
      }
    }, 0);
  }, [heights, measuredHeights]);
  const totalMenuHeight = totalHeight + paddingBottom + paddingTop;
  const menuHeight = Math.min(ensureNumber(maxHeight), totalMenuHeight);
  const estimatedItemSize = Math.floor(totalHeight / itemCount);

  const { innerRef, selectProps } = props;

  const { classNamePrefix, isMulti } = selectProps || {};
  const list = React.useRef<List>(null);

  React.useEffect(() => {
    setMeasuredHeights({});
  }, [props.children]);

  // method to pass to inner item to set this items outer height
  const setMeasuredHeight = ({
    index,
    measuredHeight,
  }: {
    index: number;
    measuredHeight: number;
  }) => {
    if (
      measuredHeights[index] !== undefined &&
      measuredHeights[index] === measuredHeight
    ) {
      return;
    }

    setMeasuredHeights((measuredHeights) => ({
      ...measuredHeights,
      [index]: measuredHeight,
    }));

    // this forces the list to rerender items after the item positions resizing
    if (list.current) {
      list.current.resetAfterIndex(index);
    }
  };

  React.useLayoutEffect(() => {
    /**
     * enables scrolling on key down arrow
     */

    // don't scroll if less then 5 items in the dropdown
    if (children.length <= 5) {
      return;
    }

    const now = performance.now();

    const totalDiff = now - (timeSinceLastKeyboardNavigationRef?.current || 0);

    // Only scroll if the last keyboard navigation was more than 100ms ago. This prevents
    //  the list from scrolling when the user is hovering items currently in the scroll viewport
    //  with their mouse.
    if (totalDiff > 100) {
      return;
    }

    if (currentIndex >= 0 && list.current !== null) {
      list.current.scrollToItem(currentIndex, "smart");
    }
  }, [currentIndex, children, list]);

  return (
    <List
      className={classNames(
        classNamePrefix
          ? `${classNamePrefix}__menu-list${
              isMulti ? ` ${classNamePrefix}__menu-list--is-multi` : ""
            }`
          : "",
        classes
      )}
      style={menuListStyle as any}
      ref={list}
      outerRef={innerRef}
      estimatedItemSize={estimatedItemSize}
      innerElementType={React.forwardRef(
        ({ style, ...rest }, ref: React.ForwardedRef<HTMLDivElement>) => (
          <div
            ref={ref}
            style={{
              ...style,
              height: `${
                parseFloat(style.height) + paddingBottom + paddingTop
              }px`,
              width: "calc(100% - 8px)",
            }}
            {...rest}
          />
        )
      )}
      height={menuHeight}
      width="100%"
      itemCount={itemCount}
      itemData={children}
      itemSize={(index: number) => measuredHeights[index] || heights[index]}
    >
      {({ data, index, style }: ListChildProps<React.ReactNode[]>) => {
        return (
          <div
            style={{
              ...style,
              top: `${
                (style.top ? parseFloat(style.top.toString()) : 0) + paddingTop
              }px`,
            }}
            onMouseOver={() => {
              if (wasMouseFocusRef) {
                wasMouseFocusRef.current = true;
              }
            }}
            onTouchMove={() => {
              if (wasMouseFocusRef) {
                wasMouseFocusRef.current = true;
              }
            }}
          >
            <MenuItem
              data={data[index]}
              index={index}
              setMeasuredHeight={setMeasuredHeight}
            />
          </div>
        );
      }}
    </List>
  );
}

function MenuItem({
  data,
  index,
  setMeasuredHeight,
}: {
  data: React.ReactNode;
  index: number;
  setMeasuredHeight: (value: { index: number; measuredHeight: number }) => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);

  // using useLayoutEffect prevents bounciness of options of re-renders
  React.useLayoutEffect(() => {
    if (ref.current) {
      const measuredHeight = ref.current.getBoundingClientRect().height;

      setMeasuredHeight({ index, measuredHeight });
    }
  }, [ref.current]);

  return (
    <div
      key={`option-${index}`}
      ref={ref}
      style={{
        width: "calc(100% - 8px)",
      }}
      className="flex items-center justify-start gap-2"
    >
      {data}
    </div>
  );
}
